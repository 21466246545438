<template>
    <div class="w-3/4">
        <form autocomplete="off" @submit.prevent class="py-5">
            <h5 class="py-2" style="font-size:14px;">Terms And Conditions</h5>
            <p class="font-12 mb-4" style="font-size:12px;">Please ensure that you have read the terms and conditions
                linked below
                before you
                acknowledge and confirm.</p>
            <vs-row vs-w="12">
                <vs-col vs-w="24" class="flex flex-row item-center">
                    <div class="inline-block">
                        <vs-checkbox v-model="confirm" style="margin-left: 0" />
                    </div>
                    <div class="inline-block">
                        <p style="font-size:12px;">I confirm that
                            I
                            have read and acknowledge the <a :href="link" target="_blank">terms and conditions</a></p>
                    </div>
                </vs-col>
            </vs-row>
        </form>
    </div>
</template>


<script>
import Datepicker from "vuejs-datepicker";
export default {
    components: {
        Datepicker
    },
    name: "PatientForm",
    props: ["onSave", "insertPatient", "link"],
    data() {
        return {
            confirm: false
        }
    },
    watch: {
        onSave: async function (val) {
            if (val) {
                if (this.confirm) {
                    this.$emit("insertPatient", false);
                } else {
                    this.$vs.notify({
                        title: "Terms and Conditions",
                        text: "Please accept the terms and conditions.",
                        color: "danger",
                    });
                }
            }
        }
    },
}
</script>
<style>
.custom-field label {
    display: block;
    padding: 5px 0 !important;
    font-size: 12px;
}

.bg-color-error {
    background: rgba(234, 84, 85, 0.14);
    color: rgba(234, 84, 85, 1);
}

.bg-color-done {
    background: rgba(40, 199, 111, 0.14);
    color: rgba(40, 199, 111, 1);
}

.is-danger {
    border: solid 1px rgba(234, 84, 85, 1) !important;
}

.is-true {
    border: solid 1px rgba(40, 199, 111, 1) !important;
}

.font-12 span {
    font-size: 12px !important;
}
</style>